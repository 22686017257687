.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.quiz-app{
  width: 100%;
    /* margin: 0 auto; */
    background-color: #f2f2f2;
    padding: 50px 20px;
}
.result-message{
    margin-top: 50px;
    background-color: #c3e9c3;
    border: solid 3px green;
    border-radius: 8px;
    padding: 10px;
}
.quiz-heading{
  text-transform: capitalize;
  font-size: 25px;
  color:#282c34;
}
.form-check-label{
  padding: 10px;
  width: 100%;
  cursor: pointer;
}
.form-check:hover{
  background-color: rgb(185, 185, 185);
}
.form-check{
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .quiz-app{
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}